
html, body {
	background: #060606;
}

.magic-backgrounds {
	position: fixed;
	top: -100%;
	left: -50%;
	width: 200%;
	height: 400%;
	/*background: #060606;*/
	
	width: 2048px;
	height: 2048px;
	top: -256px;
	left: -512px;
}

/*
*/
.with-animation.magic-backgrounds {
	-webkit-animation: spin 60s linear infinite;
	animation: spin 60s linear infinite;
}
.without-animation.magic-backgrounds {
	transform: rotate(30deg);
	-ms-transform: rotate(30deg);
	-webkit-transform: rotate(30deg);
}

.magic-backgrounds * {
	z-index: 0;
}

.pulse-wrapper {
	width: 128px;
	height: 128px;
	background: #222;

}

.with-animation .pulse-wrapper {
    -webkit-animation: pulse 0.5s linear infinite;
	animation: pulse 0.5s linear infinite;

    /*
    -webkit-animation: colourChangeForeground 10s linear infinite;
	animation: colourChangeForeground 10s linear infinite;
    */
}

.big-block {
	position: absolute;
	top: 0;
	left: 0;	
}
.with-animation .big-block {
	-webkit-animation: bigTwist 1s ease infinite;
	-webkit-animation-delay: 0.5s;
	
	animation: bigTwist 1s ease infinite;
	animation-delay: 0.5s;
}

.medium-block {
	position: absolute;
	top: 0;
	left: 0;
}
.with-animation .medium-block {
	-webkit-animation: mediumTwist 1s ease infinite;
	animation: mediumTwist 1s ease infinite;
}

.small-block {
	position: absolute;
	top: 0;
	left: 0;
}
.with-animation .small-block {
	-webkit-animation: smallTwist 0.5s ease infinite;
	animation: smallTwist 0.5s ease infinite;
}


/*-Animation Keyframes---------------*/

@keyframes colourChangeBackground {
    0% {
        background-color: #060606;
    }
    25% {
        background-color: #060606;
    }
    50% {
        background-color: #060606;
    }
    75% {
        background-color: #060606;
    }
    100% {
        background-color: #060606;
    }
}

@-webkit-keyframes colourChangeForeground {
    0% {
        background-color: #222;
    }
    25% {
        background-color: #1c0808;
    }
    50% {
        background-color: #081121;
    }
    75% {
        background-color: #07190f;
    }
    100% {
        background-color: #222;
    }
}

@keyframes colourChangeForeground {
    0% {
        background-color: #222;
    }
    25% {
        background-color: #1c0808;
    }
    50% {
        background-color: #081121;
    }
    75% {
        background-color: #07190f;
    }
    100% {
        background-color: #222;
    }
}




@keyframes pulse {
	50% {
		transform: scale(1.15, 1.15);
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to { 
		transform: rotate(360deg);
	}
}

@keyframes bigTwist {
	0%, 10% {
		transform: rotate(0deg);
	}
	50%, 100% { 
		transform: rotate(90deg);
	}
}

@keyframes mediumTwist {
	0%, 10% {
		transform: rotate(0deg);
	}
	50%, 100% { 
		transform: rotate(-90deg);
	}
}

@keyframes smallTwist {
	0%, 10% {
		transform: rotate(0deg);
	}
	50%, 100% { 
		transform: rotate(90deg);
	}
}

/*-Webkit Anim Keyframes-------------*/

@-webkit-keyframes pulse {
	/*0% {
		-webkit-transform: scale(0.85, 0.85);
	}
	*/
	50% {
		-webkit-transform: scale(1.15, 1.15);
	}
}

@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
	}
	to { 
		-webkit-transform: rotate(360deg);
	}
}

@-webkit-keyframes bigTwist {
	0%, 10% {
		-webkit-transform: rotate(0deg);
	}
	50%, 100% { 
		-webkit-transform: rotate(90deg);
	}
}

@-webkit-keyframes mediumTwist {
	0%, 10% {
		-webkit-transform: rotate(0deg);
	}
	50%, 100% { 
		-webkit-transform: rotate(-90deg);
	}
}

@-webkit-keyframes smallTwist {
	0%, 10% {
		-webkit-transform: rotate(0deg);
	}
	50%, 100% { 
		-webkit-transform: rotate(90deg);
	}
}

.logo-letter {
    -moz-transition: color .2s ease-in;
    -o-transition: color .2s ease-in;
    -webkit-transition: color .2s ease-in;
}

.letter-1:hover {
    color: #ff0000;
}
.letter-2:hover {
    color: #23acff;
}
.letter-3:hover {
    color: #ff0000;
}
.letter-4:hover {
    color: #23acff;
}



