html, body {
  background: #060606;
}

.magic-backgrounds {
  width: 2048px;
  height: 2048px;
  position: fixed;
  top: -256px;
  left: -512px;
}

.with-animation.magic-backgrounds {
  animation: 60s linear infinite spin;
}

.without-animation.magic-backgrounds {
  transform: rotate(30deg);
}

.magic-backgrounds * {
  z-index: 0;
}

.pulse-wrapper {
  background: #222;
  width: 128px;
  height: 128px;
}

.with-animation .pulse-wrapper {
  animation: .5s linear infinite pulse;
}

.big-block {
  position: absolute;
  top: 0;
  left: 0;
}

.with-animation .big-block {
  -webkit-animation: 1s .5s infinite bigTwist;
  animation: 1s .5s infinite bigTwist;
}

.medium-block {
  position: absolute;
  top: 0;
  left: 0;
}

.with-animation .medium-block {
  animation: 1s infinite mediumTwist;
}

.small-block {
  position: absolute;
  top: 0;
  left: 0;
}

.with-animation .small-block {
  animation: .5s infinite smallTwist;
}

@keyframes colourChangeBackground {
  0% {
    background-color: #060606;
  }

  25% {
    background-color: #060606;
  }

  50% {
    background-color: #060606;
  }

  75% {
    background-color: #060606;
  }

  100% {
    background-color: #060606;
  }
}

@keyframes colourChangeForeground {
  0% {
    background-color: #222;
  }

  25% {
    background-color: #1c0808;
  }

  50% {
    background-color: #081121;
  }

  75% {
    background-color: #07190f;
  }

  100% {
    background-color: #222;
  }
}

@keyframes pulse {
  50% {
    transform: scale(1.15);
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes bigTwist {
  0%, 10% {
    transform: rotate(0);
  }

  50%, 100% {
    transform: rotate(90deg);
  }
}

@keyframes mediumTwist {
  0%, 10% {
    transform: rotate(0);
  }

  50%, 100% {
    transform: rotate(-90deg);
  }
}

@keyframes smallTwist {
  0%, 10% {
    transform: rotate(0);
  }

  50%, 100% {
    transform: rotate(90deg);
  }
}

@-webkit-keyframes pulse {
  50% {
    -webkit-transform: scale(1.15);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes bigTwist {
  0%, 10% {
    -webkit-transform: rotate(0);
  }

  50%, 100% {
    -webkit-transform: rotate(90deg);
  }
}

@-webkit-keyframes mediumTwist {
  0%, 10% {
    -webkit-transform: rotate(0);
  }

  50%, 100% {
    -webkit-transform: rotate(-90deg);
  }
}

@-webkit-keyframes smallTwist {
  0%, 10% {
    -webkit-transform: rotate(0);
  }

  50%, 100% {
    -webkit-transform: rotate(90deg);
  }
}

.logo-letter {
  -o-transition: color .2s ease-in;
  -webkit-transition: color .2s ease-in;
  -moz-transition: color .2s ease-in;
}

.letter-1:hover {
  color: red;
}

.letter-2:hover {
  color: #23acff;
}

.letter-3:hover {
  color: red;
}

.letter-4:hover {
  color: #23acff;
}

/*# sourceMappingURL=index.20ef8d92.css.map */
